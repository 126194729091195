import { object, string } from 'yup';

const validations = object({
  firstName: string().required().label('First name'),
  lastName: string().required().label('Last name'),
  phone: string().required().label('Phone'),
  email: string()
    .email('Must be a valid email')
    .max(255)
    .required()
    .label('Email'),
});

export default validations;
